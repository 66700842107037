export const GlobalVariable = Object.freeze({
    BASE_API_URL: 'https://delrey-api.centralmed.com.ar/',
    BASE_FRONT_URL: 'https://delrey.centralmed.com.ar/',
    VERSION_FRONT: '1.0.0',
    VERSION_BACK: '1.0.0',

    BROADCASTER: 'pusher',
    KEY: 'local',
    WS_HOST: '127.0.0.1',
    WS_PORT: 6001,
    DISABLE_STATS: true,
    FORCE_TLS: false,
    ENCRYPTED: false,
    CLUSTER: 'mt1',
});
