import { Component, OnInit,Inject,AfterViewInit,OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CornerstoneService } from 'app/services/cornerstone.service';
import { GlobalVariable } from 'app/global/global';

@Component({
  selector: 'app-show-imagen',
  templateUrl: './show-imagen.component.html',
  styleUrls: ['./show-imagen.component.scss']
})
export class ShowImagenComponent implements OnDestroy,OnInit,AfterViewInit{

  constructor(
    private dialogRef: MatDialogRef<ShowImagenComponent>,
    @Inject(MAT_DIALOG_DATA) public mensaje:any,
    private csS:CornerstoneService
  ) { }

  url:string = GlobalVariable.BASE_API_URL+'storage/images/paciente/';
  urlCM:string = GlobalVariable.BASE_API_URL+'storage/images/centro-medico/carousel/';
  imageData: any;
  sideBar = true;
  login:boolean = false;
  img: any;
  extd:string;
  xlsVisualizer:string = 'https://delre-xls.centralmed.com.ar/?xls=';

  ngOnInit(): void {
    this.Init();
  }

  ngAfterViewInit(): void{
  }

  private Init() {
    if (this.mensaje instanceof File) {
      const file = this.mensaje;
      this.extd = file.name.split('.').pop();
      if (this.extd == "DCM" || this.extd == "dcm") {
        this.extd = 'dcm';
        this.imageData = window.URL.createObjectURL(file);
        this.csS
          .fetchDicomImage(this.imageData)
          .subscribe((res) => {
            this.imageData = res;
          });
      }
      else if (this.extd == "pdf" || this.extd == "PDF") {
        this.extd = 'pdf';
        this.imageData = window.URL.createObjectURL(file);
      }
      else if(this.extd == "xlsx" ){
       // this.extd = 'xlsx';
        this.imageData = "Debe guardar el estudio para poder visualizarlo";
      }
      else {
        this.extd = 'img';
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imageData = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    }
    else {
      if(!this.mensaje.nombreImagen){
        this.imageData = this.url + this.mensaje.idPaciente + '/' + this.mensaje.idEstudioMedico + '/' + this.mensaje.nombre;
        this.extd = this.mensaje.nombre.split('.').pop();
      }
      else{
        this.imageData = this.urlCM + this.mensaje.nombreImagen;
        this.extd = this.mensaje.nombreImagen.split('.').pop();
      }

      if (this.extd == "DCM" || this.extd == "dcm") {
        this.extd = 'dcm';
        this.csS
          .fetchDicomImage(this.imageData)
          .subscribe((res) => {
            this.imageData = res;
          });
      }
      else if (this.extd == "pdf" || this.extd == "PDF") {
        this.extd = 'pdf';
      }
      else if(this.extd == "xlsx"){
        this.extd = 'xlsx';
        this.imageData = this.xlsVisualizer+this.imageData;
      }
      else{
        this.extd = 'img';
      }
    }
  }

  openSideBar(event) {
    this.sideBar = !this.sideBar;
  }

  onClickNo(){
    this.dialogRef.close();
  }

  ngOnDestroy(){
    this.csS = null;
  }

}
